import React from "react"
import { graphql } from "gatsby"
import { CustomLink } from "./customlink"
import { useStaticQuery } from "gatsby"

export const NavigationTemplate = ({ navigation, location, className }) => (
  <nav className={className}>
    <div className="mt-2 uppercase font-bold text-base">
      {navigation.length > 0 && (
        <ul className="flex flex-wrap">
          {navigation.map(item => (
            <li
              key={item.href}
              className={`hover:text-white mr-2 pt-2 pr-2 pl-0 pb-4`}
            >
              <CustomLink
                linktype={item.linktype}
                href={item.href}
                className=""
              >
                {item.label}
              </CustomLink>
            </li>
          ))}
        </ul>
      )}
    </div>
  </nav>
)

export const query = graphql`
  fragment LinkInformation on ComponentsYamlNavigation {
    href
    label
    linktype
    order
    visibility
  }
  fragment Navigation on ComponentsYamlConnection {
    edges {
      node {
        navigation {
          ...LinkInformation
        }
      }
    }
  }
`

export const Navigation = ({ type, location, className = "" }) => {
  const data = useStaticQuery(
    graphql`
      query {
        header: allComponentsYaml(filter: { key: { eq: "header" } }) {
          ...Navigation
        }
        footer: allComponentsYaml(filter: { key: { eq: "footer" } }) {
          ...Navigation
        }
      }
    `
  )

  const navigation = data[type].edges[0].node.navigation
    .filter(x => x.visibility === "visible")
    .sort((a, b) => a.order - b.order)
  return (
    <NavigationTemplate
      navigation={navigation}
      className={className}
      location={location}
    />
  )
}
