import React from "react"
import "./layout.css"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Navigation } from "./navigation"

const Skeleton = ({ header, main, footer }) => (
  <div className="bg-secondary-100 font-sans">
    <div className="tk-sm:max-w-2xl tk-sm:mx-auto">
      <div className="flex flex-col min-h-screen">
        <div className="w-full bg-primary-700">
          <div className="sm:max-w-2xl sm:mx-auto">
            <div className="w-full">{header}</div>
          </div>
        </div>
        <div className="flex-1 bg-secondary-100">
          {/* <div className="sm:max-w-2xl h-full sm:mx-auto flex-1 bg-primary-100 "> */}
          {main}
        </div>
        {/* </div> */}
        <div className="bg-primary-700">
          <div className="sm:max-w-2xl sm:mx-auto">{footer}</div>
        </div>
      </div>
    </div>
  </div>
)

const LogoAndTitle = ({ logo }) => (
  <div>
    <Link to="/" className="w-auto">
      <div className="flex content-center flex-wrap">
        <div className="mr-4">
          <Img
            className="rounded-full border-solid border-2 border-primary-100"
            fixed={logo.childImageSharp.fixed}
          />
          <img
            alt="Logo: Parents for Future Germany"
            className="hidden"
            src={"/images/logo-parents-4-future-earth.svg"}
            width="50"
            height="50"
          />
        </div>
        <div className="my-3">
          <h3 className="text-xl  leading-none tk-mt-1 my-auto hover:text-white">
            Parents for Future
            <br />
            Marburg
          </h3>
        </div>
      </div>
    </Link>
  </div>
)

const Header = ({ logo, location }) => (
  <header>
    <div className="bg-primary-700 text-primary-100 font-semibold px-3 pt-3">
      <LogoAndTitle logo={logo} />
      <Navigation type="header" location={location} />
    </div>
  </header>
)

const Footer = ({ location }) => (
  <footer className="bg-primary-700 h-auto px-3 pb-3 text-primary-100">
    <Navigation type="footer" className="pt-2" location={location} />
    <div className="py-3 text-left text-sm">
      © Parents for Future Marburg {new Date().getFullYear()}
    </div>
  </footer>
)

export default function(props) {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
      # header: allComponentsYaml(filter: { key: { eq: "header" } }) {
      #  ...Navigation
      # }
      logo: file(
        relativePath: { eq: "images/logo-parents-for-future-marburg.png" }
      ) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 65, height: 65) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      file(relativePath: { eq: "images/logo-parents-4-future-earth.svg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  // return <Skeleton header="HEADER" main="MAIN" footer="FOOTER" />

  return (
    <Skeleton
      header={<Header logo={data.logo} location={props.location} />}
      main={
        <div className="h-full">
          <div className="hidden">
            <h3>{props.location.pathname}</h3>
          </div>
          <main className="h-full py-3">
            <div className="h-full">{props.children}</div>
          </main>
        </div>
      }
      footer={<Footer />}
    />
  )

  /*
  return (
    <div className="flex flex-col bg-blue-lightest">
      <div className="flex flex-row">
        <div class="flex-1 bg-primary-100">
          <div class="max-w-2xl mx-auto px-2">
            <div className="bg-secondary-100 font-sans">
              <Header logo={data.logo} />
              <div className="hidden">
                <h3>{props.location.pathname}</h3>
              </div>
              <main>
                <div>
                  <div className="px-2">{props.children}</div>
                </div>
              </main>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  */
}
