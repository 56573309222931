import React, { useState } from "react"

export const SmMxAuto = ({ children, className = "", style }) => (
  <div className={`sm:mx-auto bg-primary-100 ${className}`} style={style}>
    {children}
  </div>
)

export const Max2xl = ({ children, className = "", style }) => (
  <SmMxAuto className={`sm:max-w-2xl ${className}`} style={style}>
    {children}
  </SmMxAuto>
)

export const Max3xl = ({ children, className = "" }) => (
  <SmMxAuto className={`sm:max-w-3xl ${className}`}>{children}</SmMxAuto>
)

export const Max4xl = ({ children, className = "" }) => (
  <SmMxAuto className={`sm:max-w-4xl ${className}`}>{children}</SmMxAuto>
)

export const Max5xl = ({ children, className = "" }) => (
  <SmMxAuto className={`sm:max-w-5xl ${className}`}>{children}</SmMxAuto>
)

export const PX3 = ({ children, className = "" }) => (
  <div className={`px-3 ${className}`}>{children}</div>
)

export const P = ({ children, className = "" }) => (
  <p className={`pt-3 pb-3 leading-relaxed text-lg ${className}`}>{children}</p>
)

export const H1 = ({ children, margins, id }) => (
  <div className="">
    <h1
      id={id}
      className={`text-4xl sm:text-5xl font-light text-primary-600 ${margins ||
        "pt-8 pb-3"}`}
    >
      {children}
    </h1>
  </div>
)

export const H2 = ({ children, margins, id }) => (
  <div className="">
    <h2
      id={id}
      className={`text-primary-700 bg-primary-100 text-3xl ${margins ||
        "pt-6 pb-3"}`}
    >
      {children}
    </h2>
  </div>
)

export const H3 = ({ children, margins, id }) => (
  <h3 id={id} className={`text-primary-700 text-2xl ${margins || "pt-6 pb-3"}`}>
    {children}
  </h3>
)

export const H4 = ({ children }) => (
  <h4 className="text-primary-700 text-xl pt-6 pb-2">{children}</h4>
)

export const H5 = ({ children }) => (
  <h5 className="text-primary-700 font-bold pt-6 pb-2">{children}</h5>
)

export const H6 = ({ children }) => (
  <h6 className="text-primary-700 text-sm font-bold pt-6 pb-1">{children}</h6>
)

export const Button = ({ children, onClick }) => (
  <button className="bg-primary-500 text-primary-100 px-2 pt-1 rounded" onClick>
    {children}
  </button>
)

export const ButtonLink = ({ children, href }) => (
  <a href={href} target="_blank" rel="noreferrer">
    <div className="bg-primary-600 text-lg font-bold text-center text-primary-100 px-2 pt-1 rounded">
      {children}
    </div>
  </a>
)

export const Email = function({ to, domain }) {
  const [visible, setVisible] = useState(false)

  if (visible)
    return (
      <a className="pl-1 my-2 text-primary-700" href={`mailto:${to}@${domain}`}>
        {`${to}@${domain}`}
      </a>
    )
  return (
    <button
      className="bg-primary-500 text-primary-100 px-2 pt-1 rounded"
      onClick={() => setTimeout(() => setVisible(true), 1000)}
    >
      E-Mail-Adresse anzeigen
    </button>
  )
}

export const UList = ({
  items,
  renderItem,
  getKey,
  className = "",
  liClassName = "",
}) => (
  <ul className={className}>
    {items.map(item => (
      <li className={liClassName} key={getKey(item)}>
        {renderItem(item)}
      </li>
    ))}
  </ul>
)

export const HList = ({ items, renderItem, getKey, className = "" }) => (
  <ul className={`hlist ${className}`}>
    {items.map(item => (
      <li className="inline-block mr-1" key={getKey(item)}>
        {renderItem(item)}
      </li>
    ))}
  </ul>
)

export const IconBox = ({ icon, children, className = "" }) => (
  <div className={`flex items-start fill-current ${className}`}>
    <span className="w-4 h-4 mr-4 fill-current">{icon}</span>
    {children}
  </div>
)
