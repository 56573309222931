import React from "react"
import { Link } from "gatsby"

export const CustomLink = ({ linktype, href, children, className = "" }) => {
  if (linktype === "internal") {
    return (
      <Link
        className={className}
        to={href}
        activeClassName="text-white border-b-2"
      >
        {children}
      </Link>
    )
  } else {
    return (
      <a className={className} href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    )
  }
}

export const LinkItem = ({ to, header, main, footer }) => (
  <Link to={to}>
    <div className="bg-secondary-100 hover:bg-secondary-200 rounded p-3 my-3">
      {header}
      <h3 className="pt-4 text-3xl text-primary-600">{main}</h3>
      {footer}
    </div>
  </Link>
)
